import { useContext, useEffect, useRef } from 'react'
import Radar from 'radar-sdk-js'
import { AuthContext } from '../../../context/authProvider'
import { sendUserLocation } from '../../../adapters/user'
import { useGrowthbookFeature } from '../../../hooks/useGrowthbook'
import storageService from '../../../services/storageService'
import { EnvConfig } from '../../../config/EnvConfig'

export const GeolocationTracker = () => {
  const user = storageService.getUser()
  const { isLoggedIn, logoutUser } = useContext(AuthContext)
  const isGeolocationEnabled = useGrowthbookFeature('geolocation_check')
  const skipGeolocationApp = useGrowthbookFeature('fe_igp_geolocation_skip_app', false)
  const geolocationInterval = useGrowthbookFeature('fe_igp_geolocation_interval', 5)
  const intervalRef = useRef(null); // Ref for managing the interval

  const initRadar = () => {
    Radar.initialize(EnvConfig.GATSBY_RADAR_KEY, {
      logLevel: 'info',
      locationTimeout: 10000
    })

    Radar.setUserId(user.id);

    Radar.onTokenUpdated((data) => {
      const { token, passed } = data
      sendUserLocation(data)
      if (!token || !passed) {
        console.log('Location invalid, logging user out...')
      }
    });

    const radarSettings = {
      skipVerifyApp: skipGeolocationApp,
      interval: geolocationInterval
    }
    console.log('Initializing tracking', radarSettings)

    Radar.startTrackingVerified(radarSettings).catch(err => console.error('Radar:', err));
  }

  const handleLocationPermission = (state) => {
    if (state === 'granted') {
      console.warn('[RADAR] INIT', state)
      initRadar()
    }

    if (state === 'denied') {
      logoutUser()
    }
  }

  // useEffect(() => {
  //   if (!isLoggedIn || !isGeolocationEnabled) return

  //   navigator.geolocation.getCurrentPosition((result) => {
  //     console.warn('[RADAR] GEOLOCATION RESULT', result)
  //     handleLocationPermission('granted')
  //   })

  //   navigator.permissions.query({ name: 'geolocation' }).then((result) => {
  //     const { state } = result
  //     handleLocationPermission(state)

  //     result.addEventListener('change', () => {
  //       handleLocationPermission(result.state)
  //     })
  //   })

  //   return () => {
  //     //clearInterval(radarIntervalRef.current)
  //   }
  // }, [isLoggedIn, isGeolocationEnabled])


  const checkRadarVerification = async () => {
    Radar.trackVerified({
      skipVerifyApp: skipGeolocationApp,
      userId: user.id
    }).then((result) => {
      console.debug(result)
      const { passed, token, expiresIn, user } = result;
      sendUserLocation({ token })
    }).catch((err) => {
      console.debug(err.status)
      if (err.status.includes('ERROR_RATE_LIMIT') || err.message.includes('ERROR_LOCATION')) {
        setTimeout(() => console.log(`${err.status} - Wait`), 3000);
      } else if (err.status.includes('ERROR_PERMISSIONS') || err.status.includes('ERROR_NETWORK') || err.status.includes('ERROR_VERIFY_APP')) {
        console.debug(`${new Date().toISOString()} ${err.status}`);
        logoutUser(err.status.toLowerCase())
      } else {
        console.debug(`${new Date().toISOString()} ${err.status}`, err);
      }
    });
  }

  useEffect(() => {
    if (!isLoggedIn || !isGeolocationEnabled) return

    Radar.initialize(EnvConfig.GATSBY_RADAR_KEY, {
      logLevel: 'info'
    })

    Radar.setUserId(user.id);

    intervalRef.current = setInterval(checkRadarVerification, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isLoggedIn, isGeolocationEnabled])

  return null
}