import styled from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.8em;
  height: 100%;

  * {
    text-align: center;
  }

  ${themeMedia.desktop`
    br {
      display: none;
    }
  `}

  a {
    color: ${themeStyles.colors.black.main};
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.9em;
  margin: auto 0 1.5em;
`

export const StyledSpinnerWrapper = styled.div`
  margin: 0.6em auto 2.25em;

  ${themeMedia.maxTablet`
    margin: 0.7em auto 2.5em;
  `}
`
