import storageService from '../services/storageService'
import { SortTypes } from './constants'
import allLocales from '../i18n/locales'
import { useLocalePathPrefix } from './getWebsiteUrl'

export const generateUEID = () => {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  first = ('000' + first.toString(36)).slice(-3)
  second = ('000' + second.toString(36)).slice(-3)

  return first + second
}

export const parseQueryString = (string) => {
  const query = {}

  if (string) {
    string = string.charAt(0) === '?' ? string.slice(1) : string
    string.split('&').forEach((queryParam) => {
      const [key, value] = queryParam.split('=')
      query[key] = value
    })
  }

  return query
}

export const removeLocaleFromURL = (url) => {
  if (!useLocalePathPrefix && url) return url
  if (!url) return '/'
  if (!url.includes('?') && url.charAt(url.length - 1) != '/') url += '/'

  Object.keys(allLocales).forEach((locale) => {
    url = url.replaceAll(`/${locale}/`, '/')
  })
  return url || '/'
}

export const removeHTMLFromString = (string) => {
  if (!isBrowser()) return
  const tmp = document.createElement('div')
  tmp.innerHTML = string
  return tmp.textContent || tmp.innerText || ''
}

export const sortGames = (games, sortKeyword) => {
  const safeGames = [...games]

  switch (sortKeyword) {
    case SortTypes.A_Z:
      return safeGames.sort((a, b) => a.title.localeCompare(b.title))
    case SortTypes.Z_A:
      return safeGames.sort((a, b) => b.title.localeCompare(a.title))
    case SortTypes.NEWEST_OLDEST:
      return safeGames.sort((a, b) => a.created_at.localeCompare(b.created_at))
    case SortTypes.OLDEST_NEWEST:
      return safeGames.sort((a, b) => b.created_at.localeCompare(a.created_at))
    case SortTypes.POPULAR:
      return safeGames.sort((a) =>
        a.game_tags?.includes('gametag_top') ? -1 : 0
      )
    default:
      return safeGames
  }
}

export const getCasinoBackLink = (casinoSlug, liveCasinoSlug) => {
  const lobby = storageService.getValue('lobby')

  if (lobby?.includes(liveCasinoSlug)) {
    return `/${liveCasinoSlug}/`
  } else {
    return `/${casinoSlug}/`
  }
}

/**
 * Checks if the code is running in a browser environment.
 *
 * @returns {boolean} Returns `true` if in a browser environment, otherwise `false`.
 */
export const isBrowser = () => {
  return typeof window !== 'undefined'
}

export const isMobile = () => {
  if (typeof window === 'undefined') return false
  return Boolean(
    window.navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  )
}
