import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import PhoneIcon from '../../../../../images/icon_phone_outline_gray.svg'
import { VerifyEvery60SecondsContent } from '../../../../molecules/verifyEvery60SecondsContent'
import { useTranslation } from '../../../../../context/translationProvider'
import { useFeatureValue } from '@growthbook/growthbook-react'
import {
  doSendValidationSms,
  doValidateSms,
} from '../../../../../adapters/auth'
import { toast } from '../../../../atoms'
import {
  removeNationalIdMask,
  removePhoneMask,
} from '../../../../../helpers/mask'

const PhoneVerificationStep = (props) => {
  const { getValues, onCompleteStep, stepIsDone } = props
  const { translate } = useTranslation()
  const duration = useFeatureValue('registration_timer_to_resend')
  const mobileNumber = getValues('mobileNumber')
  const mobilePrefix = getValues('mobilePrefix')
  const fullNumber = `${mobilePrefix} ${mobileNumber}`

  const onResend = async () => {
    if (stepIsDone) return
    const { ok, data } = await doSendValidationSms(
      mobilePrefix,
      removePhoneMask(mobileNumber)
    )

    if (!ok || data?.mobileVerificationStatus !== 'REQUESTED') {
      toast.error(translate('registration.error.sms.sending'))
    }
  }

  const onInputFieldDone = async ({ verification_code }) => {
    const national_id = getValues('national_id')

    const { ok, data } = await doValidateSms(
      mobilePrefix,
      removePhoneMask(mobileNumber),
      verification_code,
      removeNationalIdMask(national_id)
    )

    if (!ok || data?.mobileVerificationStatus !== 'VERIFIED') {
      return toast.error(translate('registration.error.sms.validating'))
    }
    onCompleteStep()
  }

  useEffect(() => {
    if (stepIsDone) {
      onCompleteStep()
    }
  }, [])

  return (
    <VerifyEvery60SecondsContent
      useResendOnStart
      onResend={onResend}
      icon={PhoneIcon}
      duration={duration}
      onInputFieldDone={onInputFieldDone}
      ctaText={translate('verification.phone.buttonText')}
      title={translate('verification.phone.title')}
      description={translate('verification.phone.description').replaceAll(
        '{phone}',
        fullNumber
      )}
      countdownTextTicking={translate('verification.phone.60seconds')}
      countdownTextPaused={translate('verification.phone.clickBelow')}
    />
  )
}

PhoneVerificationStep.defaultProps = {
  getValues: () => {},
  onCompleteStep: () => {},
  stepIsDone: false,
}

PhoneVerificationStep.propTypes = {
  getValues: PropTypes.func,
  onCompleteStep: PropTypes.func,
  stepIsDone: PropTypes.bool,
}

export { PhoneVerificationStep }
