import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledLoadingScreen, loadingTransitionFinished } from './styles'
import { AuthContext } from '../../../context/authProvider'
import { isMobileApp } from '../../../utils/mobileAppFunctionality'

const LoadingScreen = (props) => {
  const { dontAddLoadingBetweenPages } = props
  const { isLoggedIn } = useContext(AuthContext)

  const [hideScreen, setHideScreen] = useState(
    dontAddLoadingBetweenPages ? isLoggedIn === null : true
  )
  const [isDoneLoading, setIsDoneLoading] = useState(!hideScreen)

  const hideLoadingScreen = (delay) => {
    setTimeout(() => {
      setIsDoneLoading(true)
    }, delay)
  }

  useEffect(() => {
    if (hideScreen) {
      setHideScreen(isLoggedIn === null)
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!hideScreen) {
      hideLoadingScreen(loadingTransitionFinished * 2)
    }
  }, [hideScreen])

  if (isDoneLoading || isMobileApp()) return <></>

  return (
    <StyledLoadingScreen
      className={`loading-screen-atom ${
        hideScreen ? '' : 'loading-screen-hidden'
      }`}
      hideScreen={hideScreen}
    />
  )
}

LoadingScreen.defaultProps = {
  dontAddLoadingBetweenPages: false,
}
LoadingScreen.propTypes = {
  dontAddLoadingBetweenPages: PropTypes.bool,
}

export { LoadingScreen }
