import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { navigate } from 'gatsby'
import useSlugs from '../../../hooks/useSlugs'
import { useModalContext } from '../../../context/modalProvider'
import { ModalTypes } from '../../../utils/constants'
import { isMobileApp } from '../../../utils/mobileAppFunctionality'
import storageService from '../../../services/storageService'
import { usePaymentDrawerContext } from '../../../context/paymentDrawerProvider'

const MobileRoutesListener = (props) => {
  const { is404 } = props
  const { open: openModal } = useModalContext()
  const { open: onDepositOpen } = usePaymentDrawerContext()
  const [
    promotionsSlug,
    casinoSlug,
    liveCasinoSlug,
    sportsSlug,
    liveSportsSlug,
    totoSlug,
    virtualSportsSlug,
  ] = useSlugs([
    'promotions',
    'casino',
    'live-casino',
    'sports',
    'live-sports',
    'toto',
    'virtual-sports',
  ])

  const mobileNavigationListener = (e) => {
    switch (e?.detail?.destination) {
      case 'overview':
      case 'live':
      case 'betHistory':
      case 'allSports':
        navigate(`/${sportsSlug}/?page=${e?.detail?.destination}`)
        window?.altenarWSDK?.set({ page: e?.detail?.destination })
        break

      case 'casino':
        navigate(`/${casinoSlug}/`)
        break
      case 'promotions':
        navigate(`/${promotionsSlug}/`)
        break

      case 'inbox':
        if (is404) return navigate('/')
        openModal({ type: ModalTypes.INBOX })
        break

      case 'deposit':
        if (is404) return navigate('/')
        onDepositOpen()
        break

      case 'withdraw':
        if (is404) return navigate('/')
        openModal({ type: ModalTypes.WITHDRAW })
        break

      case 'contact-us':
        if (window.isIntercomOpen) window.Intercom('hide')
        else window.Intercom('show')
        break
    }
  }

  useEffect(() => {
    if (!isMobileApp()) return

    window.gatsbyNavigate = navigate
    window.addEventListener('MOBILE_NAVIGATE_TO', (e) =>
      mobileNavigationListener(e)
    )
    storageService.setValue(
      'mobileTabsSlugs',
      JSON.stringify({
        promotionsSlug,
        casinoSlug,
        liveCasinoSlug,
        sportsSlug,
        liveSportsSlug,
        totoSlug,
        virtualSportsSlug,
      })
    )
  }, [])

  return <></>
}

MobileRoutesListener.defaultProps = {
  is404: false,
}

MobileRoutesListener.propTypes = {
  is404: PropTypes.bool,
}

export { MobileRoutesListener }
